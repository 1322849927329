import "./App.css";

import { default as zkAttestorAbiJson } from "./ZKAttestorDemo.json";
import { default as zkAttestorAddressJson } from "./address.json";

import React, { useState, useEffect } from "react";
import { ethers, BigNumber } from "ethers";

import { ConnectButton } from "@rainbow-me/rainbowkit";
import {
  useProvider,
  useConnect,
  useContractEvent,
  useContractRead,
  useContractWrite,
  useWaitForTransaction,
} from "wagmi";

const BACKEND_URL = "https://api.zkattestor.xyz/";
const BACKEND_WS_URL = "wss://api.zkattestor.xyz/";
const punkContractAddr = "0xb47e3cd837ddf8e4c57f05d70ab865de6e193bbb";

function App() {
  const zkAttestorAbi = zkAttestorAbiJson.abi;
  const zkAttestorAddress = zkAttestorAddressJson.address;

  const provider = useProvider();
  const { isConnected } = useConnect();

  // zkAttestor smart contract states
  const { data: lastUpdatedBlock } = useContractRead(
    {
      addressOrName: zkAttestorAddress,
      contractInterface: zkAttestorAbi,
    },
    "lastUpdatedBlockNumber",
    {
      watch: false,
      cacheTime: 15000,
      staleTime: 15000,
    }
  );

  const { data: lastRootBlock } = useContractRead(
    {
      addressOrName: zkAttestorAddress,
      contractInterface: zkAttestorAbi,
    },
    "lastRootBlockNumber",
    {
      watch: false,
      cacheTime: 15000,
      staleTime: 15000,
    }
  );

  // states to toggle smart contract interaction
  // const [submittedTxHashes, setSubmittedTxHashes] = useState([]);
  const [lastTxHash, setLastTxHash] = useState("");
  const [isLastTxConfirmed, setIsLastTxConfirmed] = useState(false);
  const [isLastTxFailed, setIsLastTxFailed] = useState(false);
  const [isNewTxValid, setIsNewTxValid] = useState(true);

  // wagmi hooks for smart contract interaction
  const {
    data: attestSlotData,
    error: attestSlotError,
    write: attestSlotWrite,
    status: attestSlotStatus,
  } = useContractWrite(
    {
      addressOrName: zkAttestorAddress,
      contractInterface: zkAttestorAbi,
    },
    "attestSlot",
    {
      onError(error) {
        console.log(error);
      },
      onSuccess(data) {
        // setSubmittedTxHashes(submittedTxHashes.concat(data["hash"]));
        setLastTxHash(data["hash"]);
        setIsLastTxConfirmed(false);
        setIsLastTxFailed(false);
        setIsNewTxValid(false);
      },
    }
  );

  const { data: attestSlotReceipt, isSuccess: attestSlotIsSuccess } =
    useWaitForTransaction({
      hash: attestSlotData?.hash,
      onSuccess(data) {
        if (data.status === 0) {
          setIsLastTxFailed(true);
        }
        setIsNewTxValid(true);
      },
    });

  useContractEvent(
    {
      addressOrName: zkAttestorAddress,
      contractInterface: zkAttestorAbi,
    },
    "SlotAttestationEvent",
    (event) => {
      if (lastTxHash === event[4]["transactionHash"]) {
        setIsLastTxConfirmed(true);
      }
    }
  );

  // user inputs
  const [blockNumber, setBlockNumber] = useState("0xd895ce");
  const [isBlockNumberValid, setIsBlockNumberValid] = useState(true);

  const setAndValidateBlockNumber = async (val: string) => {
    setBlockNumber(val);

    if (!ethers.utils.isHexString(val)) {
      setIsBlockNumberValid(false);
    } else {
      let num = BigNumber.from(val);
      if (!num.eq(BigNumber.from("0xd895ce"))) {
        setIsBlockNumberValid(false);
      } else {
        setIsBlockNumberValid(true);
      }
    }
  };

  const [punkIndex, setPunkIndex] = useState("1");
  const [isPunkIndexValid, setIsPunkIndexValid] = useState(true);

  const setAndValidatePunkIndex = async (val: string) => {
    setPunkIndex(val);

    if (!ethers.utils.isHexString(val) && isNaN(+val)) {
      setIsPunkIndexValid(false);
    } else {
      setIsPunkIndexValid(true);
    }
  };

  // states for  ZK proof generation states
  const [proofJson, setProofJson] = useState("");
  const [isProofValid, setIsProofValid] = useState(false);
  const [publicJson, setPublicJson] = useState("");
  const [witnessJson, setWitnessJson] = useState("");

  const [isGenerating, setIsGenerating] = useState(false);
  const getInput = async () => {
    // assuming punkIndex and blockNumber have been sanitized

    /* commenting out for demo
    if (parseInt(blockNumber) > parseInt(lastRootBlock)) {
      return alert(
        "Proposed block to attest is too new - it has not been processed by the contract yet."
      );
    }
    */

    const paddedIndex = ethers.utils.zeroPad(
      BigNumber.from(punkIndex).toHexString(),
      32
    );
    // slot 10 of cryptopunk smartcontract is the punkIndexToAddress mapping
    const paddedSlot = ethers.utils.zeroPad(
      BigNumber.from(10).toHexString(),
      32
    );
    const dataSlot = ethers.utils.keccak256(
      ethers.utils.concat([paddedIndex, paddedSlot])
    );

    const params = {
      addr: punkContractAddr,
      dataSlot: dataSlot,
      blockNumber: blockNumber,
    };
    const inputJson: JSON = await fetch(
      BACKEND_URL + "generate_storage_input",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(params),
      }
    ).then((res) => res.json());
    return JSON.stringify(inputJson);
  };

  const getProof = (inputJson: string) => {
    console.log("Attempting to create WebSocket connection to server.");
    const ws = new WebSocket(BACKEND_WS_URL + "generate_proof");
    ws.onopen = (event) => {
      console.log(
        "WebSocket connection established. Sending proof input to server."
      );
      ws.send(inputJson);
    };

    ws.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        setProofJson(JSON.stringify(data["proof"], null, 2));
        setPublicJson(JSON.stringify(data["public"], null, 2));
      } catch (e) {
        console.log(e);
        alert("Could not parse server response");
      }
    };

    ws.onclose = (event) => {
      const code = event.code;
      console.log("WebSocket closed with code " + code);
      setIsGenerating(false);
      if (code !== 1000) {
        const reason = event.reason;
        alert("Error in proof generation: " + reason);
      } else {
        setIsProofValid(true);
      }
    };
  };

  const startProof = async () => {
    setIsGenerating(true);
    setIsProofValid(false);
    let inputJson;
    try {
      inputJson = JSON.parse(await getInput());
      if (inputJson["witness"] === null || inputJson["input"] === null)
        throw "Input JSON is not valid.";
      setWitnessJson(JSON.stringify(inputJson["witness"]));
    } catch (e) {
      setIsGenerating(false);
      console.log(e);
      return alert("Input processing failed.");
    }
    getProof(JSON.stringify(inputJson["input"]));
  };

  const attestSlot = async () => {
    if (isConnected) {
      try {
        const proof = JSON.parse(proofJson);
        const pub = JSON.parse(publicJson);
        const witness = JSON.parse(witnessJson);

        const pf = [
          BigNumber.from(proof["pi_a"][0]),
          BigNumber.from(proof["pi_a"][1]),
          BigNumber.from(proof["pi_b"][0][1]),
          BigNumber.from(proof["pi_b"][0][0]),
          BigNumber.from(proof["pi_b"][1][1]),
          BigNumber.from(proof["pi_b"][1][0]),
          BigNumber.from(proof["pi_c"][0]),
          BigNumber.from(proof["pi_c"][1]),
        ];
        const publicHash = BigNumber.from(pub[0]);
        const blockHash = BigNumber.from("0x" + witness["blockHash"]);
        const addr = witness["addr"];
        const slot = BigNumber.from("0x" + witness["slot"]);
        const pfBlockNumber = BigNumber.from("0x" + witness["pfBlockNumber"]);
        const slotValue = BigNumber.from("0x" + witness["slotValue"]);
        const merkleProof = [
          ethers.constants.HashZero,
          ethers.constants.HashZero,
          ethers.constants.HashZero,
          ethers.constants.HashZero,
          ethers.constants.HashZero,
          ethers.constants.HashZero,
          ethers.constants.HashZero,
          ethers.constants.HashZero,
        ];
        const side = BigNumber.from(0);

        /* commenting out for demo
        // Generate merkle branch
        const index = parseInt(blockNumber);
        const historicalRootIndex = index - (index % 256);
        let blockHashes = [...Array(256)];
        for (let i = 0; i < 256; i++) {
          provider.getBlock(historicalRootIndex + i).then((value) => {
            blockHashes[i] = value.hash;
          });
          console.log(blockHashes[i]);
        }
        let tree = [];
        for (let depth = 7; depth >= 0; depth--) {
          let hashLeaves = [...Array(1 << depth)];
          for (let i = 0; i < 1 << depth; i++) {
            if (depth === 7) {
              hashLeaves[i] = ethers.utils.keccak256([
                blockHashes[2 * i],
                blockHashes[2 * i + 1],
              ]);
            } else {
              hashLeaves[i] = ethers.utils.keccak256([
                tree[7 - depth][2 * i],
                tree[7 - depth][2 * i + 1],
              ]);
            }
          }
          tree.push(hashLeaves);
        }

        const merkleProof = [];
        let _side = index % 256;
        for (let i = 0; i < 8; i++) {
          if (i === 0) {
            merkleProof.push(BigNumber.from(blockHashes[_side ^ 1]));
          } else {
            merkleProof.push(BigNumber.from(tree[i - 1][_side ^ 1]));
          }
          _side = _side >> 1;
        }
        const side = BigNumber.from(index % 256);
        */

        const tx = await attestSlotWrite({
          args: [
            pf,
            publicHash,
            blockHash,
            addr,
            slot,
            pfBlockNumber,
            slotValue,
            merkleProof,
            side,
          ],
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <>
      <div className="App">
        <header className="App-header"></header>

        <div className="grid grid-cols-2 gap-x-16 gap-y-8 p-6 sm:px-56 sm:py-20">
          <div className="text-3xl sm:text-4xl justify-self-start font-bold">
            Proof of Punk
          </div>
          <div className="justify-self-end">
            <ConnectButton label="Connect" />
          </div>

          <div className="px-8 pb-8 pt-6 bg-white rounded-lg shadow-lg text-left col-span-2">
            <div className="pb-2">
              <label className="text-sm text-black font-medium">
                Block Number
              </label>
              <input
                className={`w-full p-2 rounded-lg text-gray-500 text-sm ${
                  isBlockNumberValid
                    ? ""
                    : "border-pink-500 focus:border-pink-500 focus:ring-pink-500"
                }`}
                placeholder="Enter block number"
                type="text"
                value={blockNumber}
                onChange={(e) => {
                  setAndValidateBlockNumber(e.currentTarget.value);
                }}
                id="blockNumber"
              />
              {isBlockNumberValid ? (
                ""
              ) : (
                <span className="text-xs text-red-700">
                  Invalid block number
                </span>
              )}
            </div>

            <div className="pb-2">
              <label className="text-sm text-black font-medium">
                Punk Number
              </label>
              <input
                className={`w-full p-2 rounded-lg text-gray-500 text-sm ${
                  isPunkIndexValid
                    ? ""
                    : "border-pink-500 focus:border-pink-500 focus:ring-pink-500"
                }`}
                placeholder="Enter punk number"
                type="text"
                value={punkIndex}
                onChange={(e) => {
                  setAndValidatePunkIndex(e.currentTarget.value);
                }}
                id="punkIndex"
              />
              {isPunkIndexValid ? (
                ""
              ) : (
                <span className="text-xs text-red-700">
                  Invalid punk number
                </span>
              )}
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-4 mt-4">
              <button
                type="submit"
                disabled={
                  isGenerating || !isBlockNumberValid || !isPunkIndexValid
                }
                className="w-full px-5 py-3 text-white bg-blue-600 hover:bg-blue-700 disabled:bg-blue-300 rounded-lg"
                onClick={startProof}
              >
                <span className="font-medium">Generate Proof</span>
              </button>
              <div className="px-4 py-1 text-sm text-gray-600 sm:col-span-3">
                {isGenerating ? (
                  <div role="status">
                    Generating proof on server...&nbsp;
                    <svg
                      className="inline mr-2 w-4 h-4 animate-spin fill-blue-600"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="white"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                    <span className="sr-only">
                      This usually takes a few minutes...
                    </span>
                  </div>
                ) : (
                  ""
                )}
                {isProofValid ? (
                  <div>
                    {"\u{1f4dc}"}{" "}
                    <a
                      href={`data:text/json;charset=utf-8,${encodeURIComponent(
                        proofJson
                      )}`}
                      download="proof.json"
                    >
                      Proof
                    </a>{" "}
                    and{" "}
                    <a
                      href={`data:text/json;charset=utf-8,${encodeURIComponent(
                        publicJson
                      )}`}
                      download="public.json"
                    >
                      public witnesses
                    </a>{" "}
                    generated, ready to submit to blockchain!
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>

            <div className="mt-4 grid grid-cols-1 sm:grid-cols-4">
              <button
                type="submit"
                disabled={!isConnected || !isProofValid || !isNewTxValid}
                className="w-full px-5 py-3 text-white bg-blue-600 hover:bg-blue-700 disabled:bg-blue-300 rounded-lg"
                onClick={attestSlot}
              >
                <span className="font-medium">Attest Onchain</span>
              </button>
              <div className="px-4 py-1 text-sm text-gray-600 sm:col-span-3">
                {attestSlotStatus === "success" &&
                !isLastTxConfirmed &&
                !isLastTxFailed ? (
                  <div>
                    {"\u{1f680}"} Attestation{" "}
                    <a href={`https://goerli.etherscan.io/tx/${lastTxHash}`}>
                      transaction
                    </a>{" "}
                    sent, waiting for confirmation...
                  </div>
                ) : (
                  ""
                )}
                {isLastTxConfirmed && !isLastTxFailed && !isGenerating ? (
                  <div>
                    {"\u{1f389}"} Attestation{" "}
                    <a href={`https://goerli.etherscan.io/tx/${lastTxHash}`}>
                      transaction
                    </a>{" "}
                    confirmed!
                  </div>
                ) : (
                  ""
                )}
                {isLastTxFailed ? (
                  <div>
                    {"\u{274c}"} Attestation{" "}
                    <a href={`https://goerli.etherscan.io/tx/${lastTxHash}`}>
                      transaction
                    </a>{" "}
                    failed, try again.
                  </div>
                ) : (
                  ""
                )}
              </div>
              {isProofValid && !isConnected ? (
                <span className="pt-1 text-xs text-red-700">
                  Connect wallet to submit attestation on-chain.
                </span>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
